import React, { useState } from "react";

import { INotification } from "../../interfaces/notification";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import Loading from "../../components/loading";
import MySettings, { IMFAAttemptResponse } from "../../static/settings";
import { IPageProps } from "../../interfaces/page-props";
import PageHead from "../../components/PageHead";
import ProfileUserPasswordForm from "./ProfileUserPasswordForm";
import ProfileMFAForm from "./ProfileMFAForm";

import App from "../../App";
import { Link, RouteComponentProps } from "react-router-dom";

import { Card, Tab, Tabs, Button } from "react-bootstrap";
import UrlButton from "../../components/UrlButton";
import "../../sass/profileTabs.scss";

interface IProfileState {
  mfaReset: any;
  mfaVis: boolean;
}
interface IProfileProp extends RouteComponentProps<any>, IPageProps {
  reloadMenu(): Promise<boolean>;
}
const set = new MySettings();

const Profile: React.FC<IProfileProp> = (props) => {
  const [data, setData] = useState<IProfileState>({
    mfaReset: undefined,
    mfaVis: true,
  });

  const [loading, setLoading] = useState(false);

  const toggleMFA = async (value: boolean) => {
    try {
      setData({ ...data, mfaVis: false });

      const mfaUpdateResponse = await set.mfaUpdate(value);

      if (value === true && mfaUpdateResponse.success === true) {
        const mfaResetResponse = await set.mfaReset();
        setData({ ...data, mfaReset: mfaResetResponse });
      } else {
        props.reloadMenu();
        setData({ ...data, mfaVis: true });
        const notification: INotification = {
          title: "2FA Disabled",
          data: "2FA has now been disabled.",
          variant: NOTIFICATION_TYPE.success,
          srcComponent: "Login",
          srcData: "success",
          srcFunction: "postLogin",
        };
        App.newNotification(notification);
      }
    } catch (error) {
      console.log(error);
      const notification: INotification = {
        title: "Warning",
        data: "2FA set up error: "+error,
        variant: NOTIFICATION_TYPE.warning,
        srcFunction: "toggleMFA",
        srcData: window.location.pathname,
        srcComponent: "Profile",
      };
      App.newNotification(notification);
    }
  };

  const postMFA = async (data: any) => {
    try {
      const response: IMFAAttemptResponse = await set.mfaAttempt({
        code: data.code,
        mfaAttemptToken: data.token,
      });
      if (response.success === true) {
        props.reloadMenu();
        const notification: INotification = {
          title: "Success",
          data: "2FA enabled.",
          variant: NOTIFICATION_TYPE.success,
          srcFunction: "postMFA",
          srcData: window.location.pathname,
          srcComponent: "Profile",
        };
        App.newNotification(notification);
      }else{
        window.alert("That 2FA code was wrong. Please try again.")
      }
    } catch (error) {
      console.log(error);
      const notification: INotification = {
        title: "Warning",
        data: "2FA set up error",
        variant: NOTIFICATION_TYPE.warning,
        srcFunction: "postMFA",
        srcData: window.location.pathname,
        srcComponent: "Profile",
      };
      App.newNotification(notification);
    }
  };

  const resetTours = async () => {
    try {
      await set.toursUpdate({ twoFA: false });
      const notification: INotification = {
        title: "Success",
        data: "Tours reset successfully",
        variant: NOTIFICATION_TYPE.success,
        srcFunction: "resetTours",
        srcData: window.location.pathname,
        srcComponent: "Profile",
      };
      App.newNotification(notification);
    } catch (error) {
      console.log(error);
      const notification: INotification = {
        title: "Warning",
        data: "Something went wrong",
        variant: NOTIFICATION_TYPE.warning,
        srcFunction: "resetTours",
        srcData: window.location.pathname,
        srcComponent: "Profile",
      };
      App.newNotification(notification);
    }
  };

  const postChangedPassword = async (passwordUpdateRequest: any) => {
    // console.log("postChangedPassword", passwordUpdateRequest);
    if (
      passwordUpdateRequest.newPassword !== passwordUpdateRequest.newPassword2
    ) {
      App.newNotificationError('The new passwords do not match.');
    } else {
      setLoading(true);

      try {
        const url = set.getChangePassword();
        const result = await set.doAPIRequest<any>(url, passwordUpdateRequest);

        setLoading(false);
        if (result.success) {
          const notification: INotification = {
            title: "Success",
            data: "Password Updated",
            variant: NOTIFICATION_TYPE.success,
            srcFunction: "postLogin",
            srcData: window.location.pathname,
            srcComponent: "Profile",
          };

          App.newNotification(notification);
        }else{
          let message='There was a problem. Please contact your VoIP provider.'
          if(result.error!=null){
            message=result.error;
          }
          throw message
        }
      } catch (error:any) {
        let message='There was a problem. Please contact your VoIP provider.'
        if(typeof error==='string'){
          message=error;
        }else if(error!=null && typeof error.data==='string'){
          message=error.data
        }
        App.newNotificationError(message)
      }
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <div className="content">
      <PageHead
        resellerID={props.resellerID}
        title="User Profile"
        parents={[]}
      ></PageHead>

      <Tabs id="profileTabs" className="mb-3 profileTabs">
        <Tab eventKey="Authentication" title="Authentication">
          <Card className="mb-3">
            <Card.Header as="h3">Change Password</Card.Header>

            <ProfileUserPasswordForm onSubmit={postChangedPassword} />
          </Card>
          <Card className="mb-3">
            <Card.Header as="h3">Two Factor Authentication (2FA)</Card.Header>
            <Card.Body className="text-center">
              {!props.session.usesMFA && data.mfaVis && (
                <Button onClick={() => toggleMFA(true)}>Enable 2FA</Button>
              )}
              {props.session.usesMFA && data.mfaVis && (
                <Button onClick={() => toggleMFA(false)}>Disable 2FA</Button>
              )}
            </Card.Body>
            {data.mfaReset !== undefined && (
              <ProfileMFAForm data={data.mfaReset} onSubmit={postMFA} />
            )}
          </Card>
        </Tab>
        <Tab eventKey="Integrations" title="Integrations">
          <Card className="mb-3">
            <Card.Header as="h3">365 Contacts Integration</Card.Header>
            <Card.Body className="text-center">
              Enabling this will fetch the contacts from your organisations 365 account. They will appear in a new phone book called Microsoft-365-Org.
              <br />
              Also the contacts from individual accounts will be imported as Microsoft-365-XXXX where XXXX is the name of the user.
              <br />
              You will also gain the option to push selected phone books from our platform in to 365.
            </Card.Body>
            <Card.Footer className="text-center">
              <Link to="/office365">
                <Button>Click here to enable 365 contact importing.</Button>
              </Link>
            </Card.Footer>
          </Card>
          <Card className="mb-3">
            <Card.Header as="h3">Connectwise Contacts Integration</Card.Header>
            <Card.Body className="text-center">
              Enabling this will fetch the contacts from your organisations Connectwise account. They will appear in a new phone book called Connectwise.
            </Card.Body>
            <Card.Footer className="text-center">
              <Link to="/connectwisecontactsintegration">
                <Button>Click here to enable Connectwise contact importing.</Button>
              </Link>
            </Card.Footer>
          </Card>
        </Tab>
        <Tab eventKey="Apps" title="Apps">
          <Card className="mb-3">
            <Card.Header as="h3">iOS Companion App</Card.Header>
            <Card.Body>
              <Card.Title>Add Account To iOS Companion App</Card.Title>
              <Card.Text>
                If you have our iOS app installed you can click the button below
                to generate a URL to add your account to the app. Once you have
                the URL email it to your iPhone and then click on it. The VIP
                VoIP companion app should open automatically and add the
                account.
              </Card.Text>
            </Card.Body>

            <Card.Footer className="text-center">
              <UrlButton customersContactsId="" />
            </Card.Footer>
          </Card>
        </Tab>
        <Tab eventKey="Portal Settings" title="Portal Settings">
          <Card>
            <Card.Header as="h3">Product Tours</Card.Header>
            <Card.Body className="text-center">
              <Button onClick={resetTours}>
                Click here to reset all product tours so you can see them again.
              </Button>
            </Card.Body>
          </Card>
        </Tab>
      </Tabs>
    </div>
  );
};

export default Profile;
